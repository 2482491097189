import { graphql, useStaticQuery } from 'gatsby'
import _ from 'lodash'
import React from 'react'
import Box from 'components/Box'
import Button from 'components/Button'
import Container from 'components/Container'
import Copy from 'components/Copy'
import Divider from 'components/Divider'
import Head from 'components/Head'
import Heading from 'components/Heading'
import Heading2 from 'components/Heading2'
import Layout from 'components/Layout'
import Link from 'components/Link'
import Row from 'components/Row'
import Text from 'components/Text'
import Title from 'components/Title'
import View from 'components/View'
import paths from 'constants/paths'
import useEventPageVisited from 'hooks/use-event-page-visited'
import formatString from 'libs/format-string'

export default function Careers() {
  useEventPageVisited('Careers')
  const data = useStaticQuery(graphql`
    query {
      allGreenhouseDepartment {
        nodes {
          id
          name
          jobs {
            id
            internal_job_id
            location {
              name
            }
            title
          }
        }
      }
    }
  `)

  const departments = data.allGreenhouseDepartment?.nodes?.filter((item) => item.jobs)

  return (
    <Layout className="Careers" data-test="careers">
      <View>
        <Head title="Careers" />
        <Container flush size="xlarge">
          <Title align="center" size="large">
            Join Team Bold
          </Title>
          <Row size="xlarge">
            <Copy size="large">
              <Text element="p">
                Bold is the leading healthy aging platform, offering personalized, evidence-based
                exercise programs for Medicare members that help prevent falls, reduce
                musculoskeletal pain and disability, and increase physical activity levels.
                Innovative Medicare health plans and provider groups rely on Bold to deliver
                engaging, clinically sound exercise programs that members love to use and that
                achieve significant health outcomes. Bold is backed by leading investors, including
                Andreessen Horowitz, Khosla Ventures, Rethink Impact, GingerBread Capital, Primetime
                Partners, and Maveron.
              </Text>
              <Text element="p">
                We’re passionate about building the world we all deserve to age in, one where
                everyone has the opportunity and resources to age well. We’re looking for people who
                are tenacious, action-oriented, intellectually curious and team players to join us.
              </Text>
              <Text element="p">
                If you like making things that make a difference, we want to meet you!
              </Text>
            </Copy>
          </Row>
          {!_.isEmpty(departments) && (
            <>
              <Divider />
              <Heading2 align="center" levelStyle={3}>
                Current openings
              </Heading2>
              <Container flush size="medium">
                {departments?.sort().map((item) => (
                  <Row key={item.name}>
                    <Row size="small">
                      <Heading flush level={3} levelStyle={2}>
                        {item.name}
                      </Heading>
                    </Row>
                    {item.jobs?.map((careerPosition) => (
                      <Row key={careerPosition.internal_job_id} size="small">
                        <Link
                          to={`${paths.CAREERS}${formatString.slug(careerPosition.title)}-${
                            careerPosition.internal_job_id
                          }/`}
                          color="purple">
                          <Text size="xlarge">
                            {careerPosition.title
                              .replace(' (Remote)', '')
                              .replace(' (Contract, Remote)', ' (Contract)')}
                            {careerPosition.location && (
                              <>
                                {' '}
                                (
                                {careerPosition.location?.name.replace(
                                  ', California, United States',
                                  ''
                                )}
                                )
                              </>
                            )}
                          </Text>
                        </Link>
                      </Row>
                    ))}
                  </Row>
                ))}
              </Container>
              <Divider />
            </>
          )}
          <Row size="large">
            <Container flush size="medium">
              <Box align="center">
                <Title
                  subtext="We are interested in talking to all who are passionate about improving how we age. Submit your application and we will reach out to you if you’re a good fit."
                  cta={
                    <Button to="https://boards.greenhouse.io/agebold/jobs/4073828007">
                      Apply here
                    </Button>
                  }
                  flush
                  size="large">
                  Don’t see a position that matches your skillset?
                </Title>
              </Box>
            </Container>
          </Row>
          <Text element="p" flush>
            Age Bold, Inc. is an equal opportunity employer. We are committed to a safe and
            supportive work environment in which all employees have the opportunity to participate
            and contribute to the success of the business. We do not discriminate on the basis of
            age, race, religion, sex, gender identity, sexual orientation, pregnancy status,
            national origin, disability, veteran status, or any other factor prohibited by law.
          </Text>
        </Container>
      </View>
    </Layout>
  )
}
